.right-side {
    position: absolute;
    right:0px;
    top:-35px;
    background-color: #005EB8;
    height: 100vh;
    width: 50%;

}

.right-button {
    top: 40%;
    width:25%;
    right: 35%;
    position: absolute;
    text-align:center;
    border: none;
    background-color: white;
    height:50px;
    border-radius: 5px;
    line-height:1.1em;
    font-size:1.1em;
    color: #005EB8;
    font-weight: 800;
}

.right-button:hover {
    text-align:center;
    width:25%;
    border: none;
    background-color: rgb(105, 162, 209);
    height:50px;
    border-radius: 5px;
    line-height:1.1em;
    font-size:1.1em;
    color: #005EB8;
    font-weight: 800;
}

.image-amadeus-logo {
    position: absolute;
    width: 50%;
    bottom: 10%;
    left : 50%;
    transform: translateX(-50%);
}