.page-heading {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #174ae2;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin: 0 auto;
  max-width: 960px;
  background-color: #ffffff;
}

.card {
  border: 1px solid #ccc;
  padding: 1rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  background-color: rgb(242, 243, 248);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #276be1;
}

.card-text {
  font-size: 0.9rem;
  color: #0634bc;
}

.card:link {
  font-size: 0.9rem;
  color: #0634bc;
}