.main {
    position:absolute;
    top: 100px;
    width: 30%;
    left: 35%;
    background-color: rgb(249, 247, 247);
    color: rgb(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-bottom: 50px;
    border-radius: 40px;
  }

.button1{
    background-color: #0D99FF;
    width:60%;
    height:50px;
    text-align:center;
    line-height:1.1em;
    font-size:1.1em;
    color: white;
    border: none;
    border-radius: 20px;

}

.button1:hover{
    background-color: #0581da;
    width:60%;
    height:50px;
    text-align:center;
    line-height:1.1em;
    font-size:1.1em;
    color: white;
    border: none;
    border-radius: 20px;

}

.button2{
    background-color: #075CFF;
    width:60%;
    height:50px;
    text-align:center;
    line-height:1.1em;
    font-size:1.1em;
    color: white;
    border: none;
    border-radius: 20px;
}

.button2:hover{
    background-color: #2007ff;
    width:60%;
    height:50px;
    text-align:center;
    line-height:1.1em;
    font-size:1.1em;
    color: white;
    border: none;
    border-radius: 20px;
}


  

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

h1 {
    padding-bottom: 25px;
  }