.common-heading {
    position: relative;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 25px;
    font-family: Tahoma;
    font-weight: bold;
    color: white;
    z-index: 2;
}

.login-text {
    position: absolute;
    color: white;
    left: 50%;
    font-family: Tahoma;
    transform: translateX(-50%);
    text-align: center;
    font-size: 20px;
    z-index: 2;
}