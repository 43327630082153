.footer {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #1D42C5;
    color: white;
    height: 40px;
  }
  