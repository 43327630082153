
.left-side {
    position: absolute;
    left:0;
    top: -35px;
    background-image: url("../../images/airline-img.png");
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 50%;

}

.left-button {
    top: 40%;
    width:25%;
    left: 35%;
    position: absolute;
    text-align:center;
    border: none;
    background-color: white;
    height:50px;
    border-radius: 5px;
    line-height:1.1em;
    font-size:1.1em;
    color: #005EB8;
    font-weight: 800;
}

.left-button:hover {
    top: 40%;
    width:25%;
    left: 35%;
    position: absolute;
    text-align:center;
    border: none;
    background-color: rgb(105, 162, 209);
    height:50px;
    border-radius: 5px;
    line-height:1.1em;
    font-size:1.1em;
    color: #005EB8;
    font-weight: 800;
}